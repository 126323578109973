import React from 'react';
import classNames from 'classnames';
import { withStyles } from "@material-ui/core/styles";
import { Element, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import TextField from '@material-ui/core/TextField';
import logo from './assets/logo.jpg';
import { Typography, Checkbox, FormControlLabel, Button, Grid } from '@material-ui/core';
import { CheckBoxOutlined, CheckBoxOutlineBlank } from '@material-ui/icons';

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

const SECTION_VERTICAL_PADDING = 80;

const carouselBreakpoints = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    // slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    // slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    // slidesToSlide: 1, // optional, default to 1.
  },
};

const styles = theme => ({
  root: {
    background: theme.palette.common.white,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  logoContainer: {
    height: 'calc(100vh - 152px)',
    alignSelf: 'center',
    lineHeight: 0,
    "@media (max-width: 700px)": {
      height: 'auto',
    }
  },
  logo: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  menu: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 40,
    paddingBottom: 40,
    background: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  button: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: theme.spacing(2),
    cursor: 'pointer',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    "@media (max-width: 700px)": {
      margin: theme.spacing(1),
    }
  },
  buttonWhite: {
    color: theme.palette.common.white,
    border: `solid 2px ${theme.palette.common.white}`,
    '&:hover': {
      color: theme.palette.secondary.main,
      border: `solid 2px ${theme.palette.secondary.main}`,
    }
  },
  buttonBlue: {
    color: theme.palette.primary.main,
    border: `solid 2px ${theme.palette.primary.main}`,
    '&:hover': {
      color: theme.palette.primary.light,
      border: `solid 2px ${theme.palette.primary.light}`,
    }
  },
  section: {
    flex: 1,
    padding: theme.spacing(2),
    paddingTop: SECTION_VERTICAL_PADDING,
    paddingBottom: SECTION_VERTICAL_PADDING,
    '&:nth-child(odd)': {
      background: theme.palette.common.white,
      color: theme.palette.primary.main,
    },
    '&:nth-child(even)': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    "@media (max-width: 700px)": {
      paddingTop: SECTION_VERTICAL_PADDING / 2,
      paddingBottom: SECTION_VERTICAL_PADDING / 2,
    }
  },
  sectionContent: {
    maxWidth: 900,
    margin: 'auto'
  },
  sectionTitle: {
    textAlign: 'center',
    fontSize: 40,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    paddingBottom: theme.spacing(2)
  },
  paragraph: {
    '&:not(:last-child)': {
      paddingBottom: theme.spacing(2)
    }
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  infoContainer: {
    textAlign: 'center',
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    border: `solid 2px ${theme.palette.common.white}`
  },
  infoItem: {
    fontSize: 25,
    fontWeight: 'bold',
    paddingBottom: theme.spacing(2),
    "@media (max-width: 700px)": {
      fontSize: 20,
    }
  },
  inputContainer: {
    width: '100%',
    paddingBottom: theme.spacing(2)
  },
  inputWhite: {
    color: theme.palette.common.white,
    borderBottom: `solid 1px ${theme.palette.common.white}`
  },
  inputLabelWhite: {
    color: `${theme.palette.common.white} !important`
  },
  checkboxContainer: {
    paddingBottom: theme.spacing(1)
  },
  checkbox: {
    color: 'white'
  },
  submitContainer: {
    textAlign: 'right'
  },
  separator: {
    width: 50,
    borderBottom: 'solid 2px',
    paddingTop: theme.spacing.unit * 4,
    margin: 'auto',
    marginBottom: theme.spacing.unit * 4
  },
  bold: {
    fontWeight: 'bold'
  },
  italic: {
    fontStyle: 'italic'
  },
  carousel: {
  },
  slideContainer: {
    height: 0,
    paddingBottom: '100%',
    position: 'relative'
  },
  slide: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  slideImage: {
  },
  link: {
    textDecoration: 'none',
    borderBottom: 'solid 1px',
    display: 'inline-block',
    lineHeight: 1.1,
  },
  linkBlue: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.light,
    }
  },
  linkWhite: {
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.grey['300'],
    }
  },
  linkLowerUnderline: {
    lineHeight: 1.4,
  },
  piChartSection: {
  },
  pieChartSectionTitle: {
    textAlign: 'center',
    fontSize: 27,
    fontWeight: 'bold',
    paddingBottom: theme.spacing.unit * 4,
  },
  pieChartContainer: {
    float: 'left',
    width: '50%',
    display: 'inline-block',
    "@media (max-width: 700px)": {
      width: '100%',
    }
  },
  pieChart: {
    "@media (max-width: 700px)": {
      marginBottom: theme.spacing.unit * 4
    }
  },
  pieChartYearMet: {
    height: 400,
  },
  pieChartLanguage: {
    height: 400,
  },
  pieChartTitle: {
    fontSize: 20,
    paddingBottom: theme.spacing.unit,
    textAlign: 'center'
  }
});

const imageIndexes = shuffle(Array(64).fill(0).map((_, i) => i + 1));

class AppInner extends React.Component {
  constructor(props) {
    super(props);

    this.initializeAddEventListeners = this.initializeAddEventListeners.bind(this);
  }

  state = {
    isAvecChecked: false,
    cannotMakeItChecked: false,
    addEventListenersInitialized: false,
  };

  componentDidMount() {
    const { classes } = this.props;

    this.initializeAddEventListeners();

    new window.Chartist.Pie(`.${classes.pieChartYearMet}`, {
      labels: ['2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019'],
      series: [44, 2, 7, 15, 10, 2, 12, 7]
    }, {
      labelOffset: 100,
      chartPadding: 50,
      labelInterpolationFnc: function(value) {
        if (!value) {
          return ''
        }
        return value;
      }
    }, [
      ['screen and (max-width: 700px)', {
        labelOffset: 80,
        chartPadding: 50,
      }],
    ]);

    new window.Chartist.Pie(`.${classes.pieChartLanguage}`, {
      labels: ['Swedish', '', 'Finnish', '', '', 'French', '', 'German'],
      series: [55, 0, 33, 0, 0, 10, 0, 2]
    }, {
      labelOffset: 105,
      chartPadding: 50,
      labelInterpolationFnc: function(value) {
        if (!value) {
          return ''
        }
        return value;
      }
    }, [
      ['screen and (max-width: 700px)', {
        labelOffset: 85,
        chartPadding: 50,
      }],
    ]);

  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.initializeAddEventListeners();
    }
  }

  initializeAddEventListeners() {
    const { addEventListenersInitialized } = this.state;
    const { addEventAtc } = this.props;
    if (!addEventListenersInitialized && addEventAtc) {
      addEventAtc.register('button-dropdown-click', function (obj) {
        window.gtag('event', 'added_to_calendar', {
          'event_category': obj.service,
        });
      });
      this.setState({
        addEventListenersInitialized: true
      });
    }
  }


  render() {
    const { classes } = this.props;
    const { isAvecChecked, cannotMakeItChecked } = this.state;
    return (
      <div className={classes.root} role="main" tabIndex="-1">
        <div className={classes.container}>
          <div className={classes.logoContainer}>
            <img className={classes.logo} src={logo} alt="logo" />
          </div>
          <div className={classes.menu}>
            <div className={classNames(classes.button, classes.buttonWhite)} onClick={() => {
                window.gtag('event', 'scroll_to', {
                  'event_category': 'Swedish',
                });
                scroller.scrollTo('swedishSection', {
                  duration: 500,
                  smooth: true,
                  offset: -SECTION_VERTICAL_PADDING / 2
                })
              }}>
                På svenska
            </div>
            <div className={classNames(classes.button, classes.buttonWhite)} onClick={() => {
              window.gtag('event', 'scroll_to', {
                'event_category': 'Finnish',
              });
              scroller.scrollTo('finnishSection', {
                duration: 800,
                smooth: true,
                offset: -SECTION_VERTICAL_PADDING / 2
              })
            }}>
              Suomeksi
            </div>
            <div className={classNames(classes.button, classes.buttonWhite)} onClick={() => {
              window.gtag('event', 'scroll_to', {
                'event_category': 'English',
              });
              scroller.scrollTo('englishSection', {
                duration: 800,
                smooth: true,
                offset: -SECTION_VERTICAL_PADDING / 2
              })
            }}>
              In English
            </div>
            <div className={classNames(classes.button, classes.buttonWhite)} onClick={() => {
              window.gtag('event', 'scroll_to', {
                'event_category': 'RSVP',
                'event_label': 'from_header',
              });
              scroller.scrollTo('rsvpSection', {
                duration: 800,
                smooth: true,
                offset: -SECTION_VERTICAL_PADDING / 2
              })
            }}>
              RSVP
            </div>
          </div>
          <div className={classNames(classes.section, classes.piChartSection)}>
            <style
              dangerouslySetInnerHTML={{
                __html: `
                  .ct-series-a .ct-slice-pie {
                    fill: #002F6C;
                  }
                  .ct-series-b .ct-slice-pie {
                    fill: #123674;
                  }
                  .ct-series-c .ct-slice-pie {
                    fill: #334d8e;
                  }
                  .ct-series-d .ct-slice-pie {
                    fill: #5c72b8;
                  }
                  .ct-series-e .ct-slice-pie {
                    fill: #7c90d8;
                  }
                  .ct-series-f .ct-slice-pie {
                    fill: #9fb2fe;
                  }
                  .ct-series-g .ct-slice-pie {
                    fill: #baccff;
                  }
                  .ct-series-h .ct-slice-pie {
                    fill: #deefff;
                  }
                  .ct-label {
                    color: #002F6C;
                    fill: #002F6C;
                  }
                `
              }}
            />
            <div className={classes.pieChartSectionTitle}>
              Stats for Nerds
            </div>
            <div className={classes.pieChartContainer}>
              <div className={classes.pieChartTitle}>År vi träffades / Year we met</div>
              <div className={classes.pieChart}><div className={classes.pieChartYearMet} /></div>
            </div>
            <div className={classes.pieChartContainer}>
              <div className={classes.pieChartTitle}>Huvudspråk / Main language</div>
              <div className={classes.pieChart}><div className={classes.pieChartLanguage} /></div>
            </div>
          </div>
          <div className={classNames(classes.section)}></div>
          <div className={classes.section}>
            <Element name="swedishSection" />
            <div className={classes.sectionContent}>
              <Typography variant="body1" className={classes.paragraph}>
                Kära vänner,
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Det tog 7 år, men i år kommer självständighetsdagen att bli nånting speciellt för mig, på grund av att den ska vara min första som en Finne. För dem av er som jag kanske inte hunnit berätta åt ännu, har jag egentligen blivit en officiell del av det här landet nästan för ett år sen - just före jag också fick ett jättekult brev från armén, men vi behöver inte prata mer det nu :D
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Därför skulle det vara jättetrevligt om du kunde hänga med på min Finskblivningsfest, som kommer att hållas på Fredagen den 6 december 2019 på <a className={classNames(classes.link, classes.linkBlue)} href="https://www.google.com/maps/search/Oficina+@+The+Cartel+Room,+Malmbrinken+1,+00100+Helsingfors" target="blank" rel="noopener noreferrer">Oficina</a>. Det blir en natt för att fira vårt underbara land, och särskilt dess otroliga festkultur, såklart!
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Mera information kommer senare, men save the date, och var snäll och RSVP före Söndagen den 6 oktober, så att jag kan se till att alla får gott om utrymme, mat och drinkar!
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Jag ser fram emot att träffa er alla!
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                - Silvain
              </Typography>
              <div className={classes.separator} />
              <Typography variant="body1" className={classNames(classes.paragraph, classes.bold)}>
                [EDIT 20.10.19]
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Kära vänner,
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Gästlistan är äntligen färdigställd, och jag känner mig så lycklig att få fira den där spännande kvällen med ett så trevligt sällskap.
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                För att ni ska ha rätt förväntningar kommer här några saker som ni säkert skulle vilja veta angående evenemanget :)
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Ni är förväntade från och med kl 19.30 på Oficina, som är bokat för oss hela natten. Om det blir för mycket, kommer man att kunna slinka sig in en stund hos grannen, bar The Cartel Room, som kommer att vara öppet för allmänheten.
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                På festen kommer det att serveras drinks, men man kan också köpa egna när de tar slut (eller om man måste ha sin egna specifika favoritdrink). Oficinas bar kommer att vara öppet för oss hela natten. Snacks kommer också att serveras, men jag skulle nog säga att det inte kanske är klokt att komma med en tom mage. En vän berättade att det kan tydligen bli lite farligt i såna omständigheter. Försök också att inte ta bilen, så att alla får njuta 100% av festen, inklusive vår planet ;)
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Avslutningsvis, är ni säkert alla spända över hur ni ska klä er för tillfället, så här kommer dresscoden: <span className={classes.italic}>Fancy Pants*</span>. På grund av festens datum skulle ju inte nånting annat kännas rätt!
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Det är allt för idag. Jag ser så mycket fram emot att hälsa er alla välkomna vid dörren :)
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                - Silvain
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                PS: webbsidan har uppdaterades med den här ny informationen och lite Stats for Nerds om evenemanget.
              </Typography>
              <Typography variant="body1" className={classNames(classes.paragraph, classes.italic)}>
                * enligt <a className={classNames(classes.link, classes.linkBlue)} href="https://www.yourdictionary.com/fancy-pants" target="blank" rel="noopener noreferrer">internet</a>, "Someone fancy, silly or attractive"
              </Typography>
              <div className={classes.separator} />
              <div className={classes.buttonContainer}>
                <div className={classes.button}>
                  <div title="Add to Calendar" className="addeventatc">
                    Lägg till i kalendern
                    <span className="start">12/06/2019 19:00</span>
                    <span className="end">12/07/2019 00:00</span>
                    <span className="timezone">Europe/Helsinki</span>
                    <span className="title">Silvains Finskblivningsfest</span>
                    <span className="description">Mera info på https://finskblivningsfest.silvain.space</span>
                    <span className="location">Oficina @ The Cartel Room, Malmbrinken 1, 00100 Helsingfors</span>
                  </div>
                </div>
                <div className={classNames(classes.button, classes.buttonBlue)} onClick={() => {
                  window.gtag('event', 'scroll_to', {
                    'event_category': 'RSVP',
                    'event_label': 'from_swedish',
                  });
                  scroller.scrollTo('rsvpSection', {
                    duration: 800,
                    smooth: true,
                    offset: -SECTION_VERTICAL_PADDING / 2
                  })
                }}>
                  RSVP
                </div>
              </div>
            </div>
          </div>
          <div className={classes.section}>
            <Element name="finnishSection" />
            <div className={classes.sectionContent}>
              <Typography variant="body1" className={classes.paragraph}>
                Tulossa pian... (joulukuussa 2029)
              </Typography>
              <div className={classes.buttonContainer}>
                <div className={classes.button}>
                  <div title="Add to Calendar" className="addeventatc">
                    Lisää kalenteriin
                    <span className="start">12/06/2019 19:00</span>
                    <span className="end">12/07/2019 00:00</span>
                    <span className="timezone">Europe/Helsinki</span>
                    <span className="title">Silvain's Finskblivningsfest</span>
                    <span className="description">More info at https://finskblivningsfest.silvain.space</span>
                    <span className="location">Oficina @ The Cartel Room, Malminrinne 1, 00100 Helsinki</span>
                  </div>
                </div>
                <div className={classNames(classes.button, classes.buttonWhite)} onClick={() => {
                  window.gtag('event', 'scroll_to', {
                    'event_category': 'RSVP',
                    'event_label': 'from_finnish',
                  });
                  scroller.scrollTo('rsvpSection', {
                    duration: 800,
                    smooth: true,
                    offset: -SECTION_VERTICAL_PADDING / 2
                  })
                }}>
                  RSVP
                </div>
              </div>
            </div>
          </div>
          <div className={classes.section}>
            <Element name="englishSection" />
            <div className={classes.sectionContent}>
              <Typography variant="body1" className={classes.paragraph}>
                Dear friends,
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                It took 7 years, but this year's Independence Day will be a special one for me, as it will be my first as a Finn. Indeed, for the few of you to whom I might have forgotten to tell, I became an official part of this country almost one year ago - immediately followed by a cool letter from the army, but let's not dwell on that one :D
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Therefore, I would love for you to join me at my Finskblivningsfest, on Friday 6th of December 2019 at <a className={classNames(classes.link, classes.linkBlue)} href="https://www.google.com/maps/search/Oficina+@+The+Cartel+Room,+Malminrinne+1,+00100+Helsinki" target="blank" rel="noopener noreferrer">Oficina</a>. This will be a night to celebrate this great country, and in particular its amazing party culture, of course!
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                A lot more information will come in due time, but save the date and please RSVP below before Sunday 6th of October so that I can make sure everyone gets plenty of space, food and drinks!
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Looking forward to see you there!
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                - Silvain
              </Typography>
              <div className={classes.separator} />
              <Typography variant="body1" className={classNames(classes.paragraph, classes.bold)}>
                [EDIT 20.10.19]
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Dear friends,
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                The guest list for the Finskblivningsfest has at last been finalised, and I can't believe that I will get the chance to spend this great evening in such amazing company.
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                So that you can get your expectations in order, here comes a few things you might want to know about the event :)
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                You are all awaited from 19.30 onwards at Oficina, which will be ours until the end of the night. If things get too hectic in there, you will be able to slip out for a while to the neighbour bar The Cartel Room, which will be open to anyone.
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Drinks will be served, but there will be the possibility to buy more when we run out (or if you just really crave that specific fancy drink of yours), as the Oficina bar will be open all night long for us. Snacks will also be served during the evening, but I'd say it would be wise not to come with an empty stomach. I heard from a friend it can apparently be risky in such circumstances. Similarly, try and leave the car at home so we can all enjoy the party to the fullest, including our planet ;)
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Finally, I'm sure you all can't wait to know what you'll get to wear, so here comes the long awaited dress code: <span className={classes.italic}>Fancy Pants*</span>. Given the date we are meeting, it wouldn't feel right to be anything else!
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                That's it for today, and I can't wait to greet you all at the door :)
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                - Silvain
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                PS: the website has been updated with this new information along with some Stats for Nerds about the event.
              </Typography>
              <Typography variant="body1" className={classNames(classes.paragraph, classes.italic)}>
                * according to <a className={classNames(classes.link, classes.linkBlue)} href="https://www.yourdictionary.com/fancy-pants" target="blank" rel="noopener noreferrer">the Internet</a>, "Someone fancy, silly or attractive"
              </Typography>
              <div className={classes.separator} />
              <div className={classes.buttonContainer}>
                <div className={classes.button}>
                  <div title="Add to Calendar" className="addeventatc">
                    Add to your Calendar
                    <span className="start">12/06/2019 19:00</span>
                    <span className="end">12/07/2019 00:00</span>
                    <span className="timezone">Europe/Helsinki</span>
                    <span className="title">Silvain's Finskblivningsfest</span>
                    <span className="description">More info at https://finskblivningsfest.silvain.space</span>
                    <span className="location">Oficina @ The Cartel Room, Malminrinne 1, 00100 Helsinki</span>
                  </div>
                </div>
                <div className={classNames(classes.button, classes.buttonBlue)} onClick={() => {
                      window.gtag('event', 'scroll_to', {
                        'event_category': 'RSVP',
                        'event_label': 'from_english',
                      });
                      scroller.scrollTo('rsvpSection', {
                        duration: 800,
                        smooth: true,
                        offset: -SECTION_VERTICAL_PADDING / 2
                      })
                    }}>
                      RSVP
                </div>
              </div>
            </div>
          </div>
          <div className={classes.section}>
            <Element name="rsvpSection" />
            <div className={classes.sectionContent}>
              <Typography variant="h5" className={classes.sectionTitle}>
                RSVP
              </Typography>
              <div className={classes.infoContainer}>
                <Typography variant="h5" className={classes.infoItem}>
                  When - Friday, December 6th, 2019 from 19.30
                </Typography>
                <Typography variant="h5" className={classes.infoItem}>
                  Where - <a className={classNames(classes.link, classes.linkWhite, classes.linkLowerUnderline)} href="https://www.google.com/maps/search/Oficina+@+The+Cartel+Room,+Malminrinne+1,+00100+Helsinki" target="blank" rel="noopener noreferrer">Oficina @ The Cartel Room, Malminrinne 1</a>
                </Typography>
                <Typography variant="h5" className={classes.infoItem}>
                  Dresscode - Fancy Pants
                </Typography>
              </div>
              <form action="https://formspree.io/silvain.toromanoff+finskblivningsfest@gmail.com" method="POST">
                <Grid container>
                  <Grid xs={12} item>
                    <TextField
                      name="name"
                      label="Name"
                      className={classes.inputContainer}
                      InputLabelProps={{
                        classes: {
                          root: classes.inputLabelWhite,
                          shrink: classes.inputLabelWhite
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.inputWhite
                        }
                      }}
                      required
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <TextField
                      type="email"
                      name="_replyto"
                      label="Email"
                      className={classes.inputContainer}
                      InputLabelProps={{
                        classes: {
                          root: classes.inputLabelWhite,
                          shrink: classes.inputLabelWhite
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.inputWhite
                        }
                      }}
                      required
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <TextField
                      name="message"
                      label="Message (optional)"
                      multiline={true}
                      className={classes.inputContainer}
                      InputLabelProps={{
                        classes: {
                          root: classes.inputLabelWhite,
                          shrink: classes.inputLabelWhite
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.inputWhite
                        }
                      }}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <FormControlLabel
                      className={classes.checkboxContainer}
                      control={
                        <Checkbox
                          checked={isAvecChecked}
                          onClick={(evt) => {
                            this.setState({ isAvecChecked: !isAvecChecked });
                          }}
                          checkedIcon={
                            <CheckBoxOutlined className={classes.checkbox} />
                          }
                          icon={
                            <CheckBoxOutlineBlank className={classes.checkbox} />
                          }
                        />
                      }
                      label="I'm coming with an avec (who will not RSVP directly)"
                    />
                    <TextField
                      name="avec"
                      value={isAvecChecked ? 'Yes' : 'No'}
                      type="hidden"
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <FormControlLabel
                      className={classes.checkboxContainer}
                      control={
                        <Checkbox
                          checked={cannotMakeItChecked}
                          onClick={(evt) => {
                            this.setState({ cannotMakeItChecked: !cannotMakeItChecked });
                          }}
                          checkedIcon={
                            <CheckBoxOutlined className={classes.checkbox} />
                          }
                          icon={
                            <CheckBoxOutlineBlank className={classes.checkbox} />
                          }
                        />
                      }
                      label="I cannot make it :("
                    />
                    <TextField
                      name="is-coming"
                      value={cannotMakeItChecked ? 'No' : 'Yes'}
                      type="hidden"
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <div className={classes.submitContainer}>
                      <Button
                        type="submit"
                        variant="contained"
                      >
                        { cannotMakeItChecked ?
                            "I'll miss all the fun..."
                          : isAvecChecked ? "We'll be there!"  : "I'll be there!"
                        }
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
          <div>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={carouselBreakpoints}
              // ssr={false} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={2500}
              keyBoardControl={true}
              transitionDuration={400}
              removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
            >
              {imageIndexes.map((i) => {
                return (
                  <div
                    key={i}
                    className={classes.slideContainer}
                  >
                    <div
                      className={classes.slide}
                      style={{ backgroundImage: `url(${require(`./assets/carousel/carousel_image_${i}.jpg`)})` }}
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
    );
  };
}

const StyledApp = withStyles(styles)(AppInner)

const App = () => {
  return <StyledApp addEventAtc={window.addeventatc} />
}

export default App;
